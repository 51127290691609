<template>
    <nav class="navbar">
        <div class="navbar-left">
            <ul>
                <li><router-link to="/"> Home </router-link></li>
                <li><router-link :to="{name: 'About'}"> About </router-link></li>
                <li><router-link :to="{name: 'Gallery'}"> Gallery </router-link></li>
                <li><router-link :to="{name: 'Products'}"> Products </router-link></li>
                <li><router-link :to="{name: 'Contact'}"> ContactUs </router-link></li>
                <li><router-link :to="{name: 'Feeds'}"> FeedBack </router-link></li>
                <li><router-link :to="{name: 'FeedsView'}"> ViewFeeds </router-link></li>
            </ul>
        </div>
        <div class="navbar-right">
            
            <router-link :to="{name: 'LogIn'}" v-if="!user"> <button class="btn-login">Log In</button></router-link>
            <router-link :to="{name: 'SignUp'}" v-if="!user" > <button class="btn-signup">Sign Up</button> </router-link>
            <a href="javascript:void(0)" v-if="user"> <button class="btn-login" @click="handleLogOut">Log out</button> </a>
        </div>
    </nav>
    <slot/>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  
  methods:{
    handleLogOut(){
      localStorage.removeItem('token');
      this.$store.dispatch('user', null)
    },
    logout(){
      
    }
  },
  computed:{
    ...mapGetters([
      'user'
    ])
  }
}
</script>

<style>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  
  
  /* Style for the navbar */
  .navbar {
    background-color: #333; /* Dark background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  /* Style for the left side of the navbar */
  .navbar-left ul {
    list-style-type: none;
    display: flex;
  }
  
  .navbar-left li {
    margin-right: 20px;
  }
  
  .navbar-left a {
    text-decoration: none;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    
  }
  .navbar-left a:hover{
    background: white;
    color: black
  }
  .navbar-left a.router-link-exact-active {
    color: black;
    background: white;
  }
  
  /* Style for the right side of the navbar */
  .navbar-right {
    display: flex;
    gap: 10px;
  }
  
  .btn-login {
    background-color: transparent; /* Blue button color */
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;

  }
  
  .btn-signup {
    background-color: #FFC107; /* Blue button color */
    color: black;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
  }
  
  .btn-login:hover {
    background-color: white; /* White background on hover */
    color: black; /* Blue text on hover */
  }   
  .btn-signup:hover {
    background-color: #ffe081; /* White background on hover */
    color: black; /* Blue text on hover */
  }
  
  /* Style for the mobile menu button */
  
  
  
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
  
    .navbar-left{
        margin: 10px auto;
    }
    .navbar-left ul {
        flex-direction: column;
        
    }
  
    .navbar-left li {
        margin-bottom: 10px;
    }
    .navbar-right{
        margin: 10px auto;
        display: flex;
        flex-direction: column;
    }
    
    /* Show the mobile menu button */
   
  }
</style>