<template>

    <MainForm :showHeading="label" :submitButtonLabel="buttonLabel"
        :showNameField="true" :showEmailField="true" :showPhoneField="true"
        :showCommentField="true" :pageTypeClass="buttonClass" 
        :buttonFunctionValue="value"
    />
</template>

<script>
import Main from '../layouts/Main.vue'
import MainForm from '../components/MainForm.vue'
import axios from "axios";


    export default {
        props:{
        },

     
        created(){
    this.$emit(`update:layout`, Main)
 },
 components: {
    MainForm,
 },
 data(){
        return{
            label: "Feed Backs",
            buttonLabel: "Submit",
            buttonClass: "submit2",
            value: "feed backs"
        }
    },
    methods:{
        saveData() {
     
    },
       
    }
   
    }
</script>

<style  >
   
</style>