<template>
    <div>
      <button class="account-btn" @click="handleClick">
        <i class="fas fa-angle-left"></i>
      </button>
    </div>
  <MainForm
    :showHeading="heading1"
    :showNameField="true"
    :showEmailField="true"
    :submitButtonLabel="btnLabel"
    buttonFunctionValue="updateNameEmail"
  />
  <MainForm
    :showHeading="heading2"
    :showOldPasswordField="true"
    :showPasswordField="true"
    :showConfirmPassField="true"
    :submitButtonLabel="btnLabel"
    passLabel="New Password"
    buttonFunctionValue="updatePassword"
  />
</template>


<script>
import MainForm from "../components/MainForm.vue";
// main.js or App.vue or any other entry point
import '@fortawesome/fontawesome-free/css/all.css';


export default {
  components: {
    MainForm,
  },
  data() {
    return {
      btnLabel: "Update",
      heading1: "Update Profile",
      heading2: "Update Password",
    };
  },
  methods:{
    handleClick(){
      this.$router.push('/')
    }
  },
 
}
</script>

<style scoped>
.account-btn {
  background-color: #0b6dff;
  color: white;
  border: 0;
  border-radius: 20px;
  padding: 5px 10px;
  margin-top: 2px;
  margin-left: 5px;
  font-size: 1em;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  float: left;
  cursor: pointer;
}
</style>