<template>
    <MainForm 
        pageTypeClass="submit"
        :showHeading="label"  
        :showEmailField="true"
        :submitButtonLabel="buttonLabel"
        :buttonFunctionValue="value"/>
</template>

<script>
import MainForm from '../components/MainForm.vue'
export default {
    components:{
        MainForm
    },
    data(){
        return{
            label: "Forgot Password",
            buttonLabel: "Submit",
            value: "forgot",
        }
    }
}
</script>

<style>

</style>