<template>
    <h1>
        Products page
    </h1>
</template>
<script>
import Main from '../layouts/Main.vue'

    export default {
        created(){
    this.$emit(`update:layout`, Main)
 }
    }
</script>