<template>
  <MainForm
    :showHeading="label"
    passLabel="Password"
    :showNameField="true"
    :showConfirmPassField="true"
    :showEmailField="true"
    :showPasswordField="true"
    :showGenderBox="true"
    :showSignUpLink="true"
    :submitButtonLabel="buttonLabel"
    pageTypeClass="submit"
    :buttonFunctionValue="value"
  />
</template>

<script>
import MainForm from "../components/MainForm.vue";
export default {
  components: {
    MainForm,
  },
  data() {
    return {
      label: "SignUp",
      buttonLabel: "Sign Up",
      value: "sign up",
    };
  },
};
</script>

<style>
</style>