<template>
  <button v-if="user" class="account-btn" @click="handleClick">
    Profile
  </button>
    <h1 v-if="user">
      {{ user.name }}
    </h1 >
    <h2 v-if="user">
      {{ user.email }}
    </h2>

    <h2 v-if="!user">
      You are not logged in.
    </h2>
    
</template>

<script>
// @ is an alias to /src
import Main from '../layouts/Main.vue'
import { mapGetters } from 'vuex'
import { loadRouteLocation } from 'vue-router'

export default {
  name: 'HomeView',
  
 data(){
  return {
    title: 'Home page bootstrap',
  }
 },
 methods:{
    changeTitle(){
      this.title = 'Home page bootstrap change title'
    },
    handleClick(){
      this.$router.push('/profile')
    }
 },
 computed:{
    ...mapGetters([
      'user'
    ])
 },
 
    created(){
    this.$emit(`update:layout`, Main)
    
 },
 
 
}
</script>
<style scoped>
h1,h2 {
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}
.account-btn {
  background-color: #0b6dff;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 20px;
  font-size: 0.8em;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  float: right;
  cursor: pointer;
}
</style>
