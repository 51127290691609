<template>
  <form @submit.prevent="apiFunction">
    <div class="alert alert-danger" role="alert" v-if="logInError">
      {{ logInError }}
    </div>
    <div class="alert alert-danger" role="alert" v-if="feedsError">
      {{ feedsError }}
    </div>
    <div class="alert alert-danger" role="alert" v-if="signUpError">
      {{ signUpError }}
    </div>
    <div class="alert alert-danger" role="alert" v-if="updateProfileErr">
      {{ updateProfileErr }}
    </div>
    
    <h1
      style="font-family: 'Courier New', Courier, monospace; text-align: center"
    >
      {{ showHeading }}
    </h1>

    <label v-if="showNameField"> Name: </label>
    <input
      v-if="showNameField"
      type="name"
      id="name"
      required
      v-model="model.feeds.name"
    />

    <label v-if="showEmailField"> Email: </label>
    <input
      v-if="showEmailField"
      type="email"
      id="email"
      required
      v-model="model.feeds.email"
    />

    <label v-if="showPhoneField"> Phone: </label>
    <input
      v-if="showPhoneField"
      type="number"
      id="phone"
      required
      v-model="model.feeds.phone"
    />
    <label v-if="showOldPasswordField"> Old Password: </label>
    <input
      v-if="showOldPasswordField"
      type="password"
      required
      v-model="model.feeds.oldPassword"
    />

    <label v-if="showPasswordField"> {{ passLabel }}: </label>
    <input
      v-if="showPasswordField"
      type="password"
      required
      v-model="model.feeds.password"
    />
    <label v-if="showConfirmPassField"> Confirm Password: </label>
    <input
      v-if="showConfirmPassField"
      type="password"
      required
      v-model="model.feeds.password_confirm"
    />

    <label v-if="showCommentField"> Comment: </label>
    <textarea
      v-if="showCommentField"
      id="comment"
      class="comment"
      v-model="model.feeds.comment"
    >
    </textarea>

    <label v-if="showGenderBox"> Gender: </label>

    <div class="container" v-if="showGenderBox">
      <label class="check-label">
        <input
          type="radio"
          class="gender-input"
          name="gender"
          value="male"
          v-model="this.model.feeds.gender"
        />
        <span class="gender-label">Male</span>
      </label>
      <label class="check-label">
        <input
          type="radio"
          class="gender-input"
          name="gender"
          value="female"
          v-model="this.model.feeds.gender"
        />
        <span class="gender-label">Female</span>
      </label>
      <label class="check-label">
        <input
          type="radio"
          class="gender-input"
          name="gender"
          value="other"
          v-model="this.model.feeds.gender"
        />
        <span class="gender-label">Other</span>
      </label>
    </div>

    <div class="forgot">
      <router-link v-if="showForgotLink" :to="{ name: 'Forgot' }">
        Forgot password?</router-link
      >
    </div>

    <div :class="pageTypeClass">
      <router-link v-if="showSignUpLink" :to="{ name: 'LogIn' }">
        Already have account</router-link
      >
      <router-link v-if="showLogInLink" :to="{ name: 'SignUp' }">
        Don't have account?</router-link
      >
      <button class="account-btn" @click="apiFunction(buttonFunctionValue)">
        {{ submitButtonLabel }}
      </button>
    </div>
  </form>
</template>

<script>
import feeds from "../views/FeedBack.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  props: {
    showHeading: "",
    showNameField: Boolean,
    showEmailField: Boolean,
    showPhoneField: Boolean,
    showOldPasswordField: Boolean,
    showPasswordField: Boolean,
    showConfirmPassField: Boolean,
    showCommentField: Boolean,
    showGenderBox: Boolean,
    showSignUpLink: Boolean,
    showLogInLink: Boolean,
    showForgotLink: Boolean,
    submitButtonLabel: "",
    passLabel: "Password",
    pageTypeClass: String,
    dynamicFunction: Function,
    buttonFunctionValue: String,
  },
  components: {
    feeds,
  },
  data() {
    return {
      model: {
        feeds: {
          name: "",
          email: "",
          phone: "",
          oldPassword: "",
          password: "",
          password_confirm: "",
          gender: "",
          comment: "",
        },
      },
      logInError: null,
      signUpError: null,
      feedsError: null,
      updateProfileErr: null
    };
  },

  methods: {
    async apiFunction(api) {
      console.log(api);
      let dataToSendInFeeds = {};
      let dataToSendInSignUp = {};
      let dataToSendInSignIn = {};
      let dataToSendInForgot = {};
      let dataToSendInReset = {};
      let dataToSendInUpdateNameEmail = {};
      let dataToSendInUpdatePassword = {};
      //Feed back function
      if (api == "feed backs") {
        dataToSendInFeeds = {
          name: this.model.feeds.name,
          email: this.model.feeds.email,
          phone: this.model.feeds.phone,
          comment: this.model.feeds.comment,
        };
        await axios
          .post("add", dataToSendInFeeds)
          .then((res) => {
            console.log(res.data);
            alert("Feed back send successfully");
            this.model.feeds = {
              name: "",
              email: "",
              phone: "",
              comment: "",
            };
          })
          .catch((error) => {
            this.feedsError = "Error occurred"
            setTimeout(() => {this.feedsError = null},2000)

          });
      }
      // Sign up function
      else if (api == "sign up") {
        dataToSendInSignUp = {
          name: this.model.feeds.name,
          email: this.model.feeds.email,
          password: this.model.feeds.password,
          password_confirm: this.model.feeds.password_confirm,
          gender: this.model.feeds.gender,
        };
        await axios
          .post("signup", dataToSendInSignUp)
          .then((res) => {
            console.log(res.data);
            alert("User added successfully");
            this.model.feeds = {
              name: "",
              email: "",
              password: "",
            };
            this.$router.push("/login");
          })
          .catch((error) => {
            this.signUpError = "Error Occured!";
            setTimeout(() => {this.signUpError = null},2000)

          });
      }
      // Sign in funciton
      else if (api == "log in") {
        dataToSendInSignIn = {
          email: this.model.feeds.email,
          password: this.model.feeds.password,
        };
        await axios
          .post("signin", dataToSendInSignIn)
          .then((response) => {
            console.log(response);
            alert("Logged in successfully");
            localStorage.setItem("token", response.data.token);
            this.$store.dispatch("user", response.data.user);
            this.$router.push("/");
          })
          .catch((error) => {
            this.logInError = "invalid username/password";
            setTimeout(() => {this.logInError = null},2000)

          });
      }
      // Forgot password function
      else if (api == "forgot") {
        dataToSendInForgot = {
          email: this.model.feeds.email,
        };
        await axios
          .post("forgot", dataToSendInForgot)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Reset password function
      else if (api == "reset") {
        dataToSendInReset = {
          password: this.model.feeds.password,
          password_confirm: this.model.feeds.password_confirm,
          token: this.$route.params.token,
        };
        await axios
          .post("reset", dataToSendInReset)
          .then((response) => {
            console.log(response);
            this.$router.push("/login");
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Update profile function for name and email
      else if (api == "updateNameEmail") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        dataToSendInUpdateNameEmail = {
          name: this.model.feeds.name,
          email: this.model.feeds.email,
        };
        await axios
          .post("updateNameEmail/" + this.user.id, dataToSendInUpdateNameEmail)
          .then((res) => {
            console.log(res);
            Toast.fire({
              icon: "success",
              title: "Updated successfully",
            });
          })
          .catch((err) => {
            this.updateProfileErr = "Something went wrong"
            setTimeout(() => {this.updateProfileErr = null},2000)
          });
      }
      // Update profile function for password
      else if (api == "updatePassword") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        dataToSendInUpdatePassword = {
          oldPassword: this.model.feeds.oldPassword,
          password: this.model.feeds.password,
          password_confirm: this.model.feeds.password_confirm,
        };
          await axios
          .post("updatePassword/" + this.user.id, dataToSendInUpdatePassword)
          .then((res) => {
            console.log(res);
            if(res) {
            Toast.fire({
              icon: "success",
              title: "Password Updated successfully",
            });
          }
          })
          .catch((err) => {
            this.updateProfileErr = "Something went wrong"
            setTimeout(() => {this.updateProfileErr = null},2000)

          });
          

      }
    },
  },
  computed: {
    pageType() {
      if (this.pageTypeClass === "feeds") {
        return "submit2";
      } else {
        return "submit";
      }
    },
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: #fff;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px lightgray;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.8em;
  font-family: "Courier New", Courier, monospace;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
.alert {
  padding: 20px;
  background-color: #fdc2c2; /* Red */
  border-radius: 10px;
  border: solid 3px red;
  color: red;
  margin-bottom: 15px;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  text-align: center;
}

.err {
  color: red;
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: bold;
}
.account-btn {
  background-color: #0b6dff;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 20px;
  font-size: 0.8em;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}
.submit {
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.submit2 {
  text-align: center;
}
.submit a {
  font-size: 0.8em;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
  color: gray;
  margin-top: 35px;
}
.forgot {
  margin-top: 10px;
}
.forgot a {
  font-size: 0.8em;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
  color: gray;
}

.comment {
  width: 100%;
  height: 100px;
  padding: 10px;
  text-align: left;
}
.gender-label {
  display: block;
  margin: 0 10px 0 10px;
}

.gender-input {
  display: none; /* Hide the default radio button */
}

.gender-label:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #333; /* Border for the radio button */
  border-radius: 50%; /* Make it circular */
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}

.gender-input:checked + .gender-label:before {
  background-color: #333; /* Background color when selected */
}

/* Styling for the labels */
.check-label {
  font-family: "Courier New", Courier, monospace;
  font-size: 0.8em;
  color: #333;
  cursor: pointer;
}
</style>