<template>
  <component :is="layout">
    <router-view v-model:layout="layout"/>
    
  </component>
</template>

 <script>
import axios from 'axios';
import { mapGetters } from 'vuex';

 export default {
  
   
    data(){
      return {
        layout: `div`,
      }
    },
    computed:{
      ...mapGetters([
        'user'
      ])
       
    },
   async created(){

             
      try{
          const res =  await axios.get('user');
          this.$store.dispatch('user', res.data);
      }catch(e){
        console.log(e.message);
      }
    }
 }
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  background: #f5f4f4;
}
.submit {
  text-align: center;
  display: flex;
  justify-content: space-between;
}


</style>
