import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import GalleryView from '../views/GalleryView.vue'
import ProductView from '../views/ProductView.vue'
import ContactView from '../views/ContactView.vue'
import Feeds from '../views/FeedBack.vue'
import FeedsView from '../views/FeedView.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import Reset from '../components/Reset.vue'
import Forgot from '../components/Forgot.vue'
import Profile from '../views/ProfileView.vue'


const routes = [
  {
    // path: '/reset/:token',
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: GalleryView
  },
  {
    path: '/products',
    name: 'Products',
    component: ProductView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/feeds',
    name: 'Feeds',
    component: Feeds
  },{
    path: '/feedsView',
    name: 'FeedsView',
    component: FeedsView
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
