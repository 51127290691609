<template>
    
    <MainForm 
        pageTypeClass="submit"
        :showHeading="label"  
        passLabel="Password"
        :showEmailField="true"
        :showPasswordField="true"
        :showLogInLink="true"
        :submitButtonLabel="buttonLabel"
        :showForgotLink="true"
        :buttonFunctionValue="value"/>
</template>

<script>
import MainForm from '../components/MainForm.vue'
export default {
    components:{
        MainForm
    },
    data(){
        return{
            label: "Log In",
            buttonLabel: "Log In",
            value: "log in",
        }
    }
}
</script>

<style>

</style>