<template>
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <title>Mailbox</title>
    </head>
    <body >
      <div class="mailbox" v-if="getData">
        <div>
          <div
            class="container"
            v-for="(items, index) in getData"
            :key="index.id"
          >
            <div class="item item1" @click="getDataById('name', items)">
              {{ items.name }}
            </div>
            <div class="item">
              <button class="btn" @click="getDataById('trash', items)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <div class="item item3" @click="getDataById('comment', items)">
              {{ items.comment }}
            </div>
            <!-- Add more users as needed -->
          </div>
        </div>
        <div class="user-details" v-if="showData">
          <ul class="user-list">
            <h2 class="font">
              {{ showData.name }}
            </h2>
            <br class="font" />
            <p class="font">
              {{ showData.email }}
            </p>
            <br />
            <p class="font">
              {{ showData.phone }}
            </p>
            <br />
            <p class="font">
              {{ showData.comment }}
            </p>
          </ul>
        </div>
      </div>
    </body>
  </html>
</template>
<script>
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Main from "../layouts/Main.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
     
      getData: [],
      showData: [],
      selectedUserId: null,
    };
  },
  created() {
    this.$emit(`update:layout`, Main);
  },
  mounted() {
    this.getApiData();
  },

  methods: {
    async getApiData() {
      await axios
        .get("/feeds")
        .then((response) => {
          this.getData = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataById(v, items) {
      if (v == "name" || v == "comment") {
        this.showData = items;
        console.log(this.showData);
      } else if ((v == "trash", items)) {
        var ids = items.id;
        console.log (this.selectedUserId)
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
        
          console.log(result)
          if (result.isConfirmed) {
            this.selectedUserId = items.id;
            axios.delete('/delete/' + this.selectedUserId).then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            });
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            setTimeout(() => {
              location.reload()
            }, 2000)
          }
        });
      }
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  
  background-color: #f4f4f4;
}

.mailbox {
  display: flex;
  max-width: 1200px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container {
  display: grid;
  width: 350px;
  gap: 2px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  overflow-y: auto;
  cursor: pointer;
}

.item {
  background-color: white;
}
.item1 {
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: start;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
  transition: background-color 0.3s;
}
.item3 {
  max-height: 3.6em; /* Two lines with a little extra space */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  grid-column-start: 1;
  grid-column-end: 5;
  text-align: start;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
}
.item1:hover {
  background-color: #f0f0f0;
}
.item3:hover {
  background-color: #f0f0f0;
}

.user-details {
  flex: 2;
  padding: 20px;
}
.user-list {
  list-style: none;
  text-align: start;
}
.font {
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

.btn {
  background-color: black; /* Blue background */
  border-radius: 10px; /* Remove borders */
  color: white; /* White text */
  margin-top: 5px;
  padding: 6px 8px;
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

/* Darker background on mouse-over */
.btn:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .mailbox {
    flex-direction: column;
  }

  .user-details {
    order: -1;
    border-top: 1px solid #ddd;
  }
}
</style>