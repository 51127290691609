<template>
    <h1>
        About Page changed 16
    </h1>
</template>


<script>
import Main from '../layouts/Main.vue'

    export default {
        created(){
    this.$emit(`update:layout`, Main)
 }
    }
</script>